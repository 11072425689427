.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

.title-scale {
    width: 80%;
    overflow: hidden;
}

.scrollbox {
    background: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px;
    height: 300px;  /* Adjustable based on preference */
    overflow-y: scroll;
}

.code-header {
    background-color: #e1e1e1;
    padding: 5px;
    font-weight: bold;
}

.code-content {
    white-space: pre-wrap;
    font-family: 'Courier New', Courier, monospace;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 0 auto;
}
.transparent-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.transparent-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.scaled-container {
    width: 80%;
}

.scaled-container-img {
    width: auto;
}
.scaled-image-container img {
    display: flex;
    width: 50%;
    height: auto;
    display: block;
}

.button-container-demo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-top: 20px;
}

.div-border {
    border: 2px solid #000; /* Black border with 2px thickness */
    padding: 10px; /* Optional: Add some padding inside the border */
    border-radius: 5px; /* Optional: Add rounded corners */
}

.space-above-container {
    margin-top: 20px; /* Adds space above the container */
}
.space-below-container {
    margin-bottom: 20px; /* Adds space below the container */
}

.big-bold-text {
    font-size: 1.5em; /* Increase font size */
    font-weight: bold; /* Make text bold */
}

.gray-background {
    background-color: #d3d3d3; /* Light gray */
}

.light-blue-background {
    background-color: #add8e6; /* Light blue */
}

.slightly-darker-light-blue-background {
    background-color: #87ceeb; /* Slightly darker but still light blue */
}

.bold-text {
    font-weight: bold; /* Makes text bold */
}


