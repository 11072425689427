/* App.css */
.aleph-logo {
  width: 150px;   /* Adjust width */
  margin-bottom: 20px;  /* Add some space below the image */
}

.App {
  text-align: center;
  margin-top: 20px;
  height: 100vh;  /* Ensure the App div fills the viewport */
  width: 100%;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Global button container styles */
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: nowrap;
  width: auto;
  max-width: none;
  z-index: 1;
}

@media (min-width: 768px) {
  .btn-container {
      padding: 0.25em 0.75em;
  }
}

.leaflet-container {
  width: 1000px;  /* Set the desired width for the box */
  height: 700px; /* Set the desired height for the box */
  margin: 0 auto; /* Optional: Center the map horizontally within its parent */
  margin-top: 40px; /* Move the map down by 40px */
  border: 2px solid #000; /* Optional: Add a border for a boxed appearance */
  align-items: center; 
}
