/* HomePage.css */

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: black;
}

.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
}


.content {
    width: 60%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto; /* Ensure it is horizontally centered */
    box-sizing: border-box;
}



.symbol-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden; /* Ensures the image fits inside the circle */
    animation: fadeInOut 5s infinite; /* Add fade animation to the container */
}

.aleph-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aleph-text {
    text-align: center; /* Center the text */
    font-weight: bold;  /* Make the text bold */
    font-style: italic; /* Italicize the text */
    margin-top: 1rem;   /* Add some spacing between the image and text */
    font-size: 1.5rem;  /* Adjust the font size */
    color: rgb(0, 0, 0);       /* Adjust text color if necessary */
}
.aleph-logo {
    object-fit: cover;
}

/* Fade in and fade out animation */
@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


.geolocation-text {
    text-align: center;
    font-size: 1.2rem;
    padding: 1rem;
}
.quote-container {
    text-align: center;
    margin-bottom: 1rem;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.quote {
    font-style: italic;
    margin-bottom: 2rem;
    color: rgb(0, 0, 0); /* Black in rgb */
}

.try-it-button {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.try-it-button:hover {
    background-color: #555;
}
.scale-top-panel {
    width: 60%;
    height: 60%;
    object-fit: contain;
}

.try-it-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
}