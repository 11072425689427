/* ExplorePage.css */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: black;
    color: white;
}

.explore-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.explore-content {
    text-align: center;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.header {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: rgb(0, 0, 0); /* Black in rgb */
    margin-left: auto;
    margin-right: auto;
}

.input-box {
    padding: 0.5rem;
    font-size: 1.2rem;
    width: 300px;
    text-align: center;
    border: 2px solid rgb(5, 5, 5);
    background-color: rgb(248, 244, 244);
    color: rgb(14, 13, 13);
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto
}

.button-row {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto
}

.input-box {
    margin-left: auto;
    margin-right: auto;
}

.button-container {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 2px solid rgb(12, 12, 12); /* 1.5px border */
    background-color: rgb(253, 252, 252); /* Light background */
    color: rgb(15, 14, 14);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px; /* Rounded corners */
}

/* Hover effect */
.button-container:hover {
    background-color: gray; /* Turns gray on hover */
    color: white; /* Changes text color to white on hover */
}

.loading, .error {
    font-size: 1rem;
    margin-top: 1rem;
}

/* Container for the explore results */
.table-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem; /* Adds space between the buttons and the table */
    margin-left: auto;
    margin-right: auto;
}

/* Centered Table */
.centered-table {
    border-collapse: collapse; /* Ensures borders are clean and collapse */
    width: 80%; /* Adjust width if needed */
    background-color: white;
    color: black;
    text-align: left; /* Align text to the left */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    margin-bottom: 2rem;
}

/* Table Headers */
.centered-table th {
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: left;
}

/* Table Rows */
.centered-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

/* Table Row Hover Effect */
.centered-table tr:hover {
    background-color: #f1f1f1;
}